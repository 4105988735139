import React from 'react'
import { Link } from 'gatsby'
import './overlay-block.scss'

export default function OverlayBlock(props) {
  return (
    <div className={`overlay-block ${props.ecompareOverlayBlockClass}`} style={{backgroundImage: `url(${props.image})`}}>
        <div className="container-fluid">
        <Link to={props.buttonUrl}>
            <div className="overlay-block__box">
                <div className="overlay-block__container">
                    {props.introText &&
                        <p className="overlay-block__intro">{props.introText}</p>
                    }
                    {props.blogPostTitleText &&
                        <h1 className="overlay-block__blog-title">{props.blogPostTitleText}</h1>
                    }
                    {props.headingText &&               
                        <h2 className="overlay-block__heading">{props.headingText}</h2>
                    }
                    {props.descriptionOneText &&
                        <p className="overlay-block__description">{props.descriptionOneText}</p>
                    }
                    {props.descriptionTwoText &&
                        <p className="overlay-block__description">{props.descriptionTwoText}</p>
                    }
                </div>
                <div className="overlay-block__button-container">
                    {props.buttonText &&
                        <button to={props.buttonUrl} className="button-primary overlay-block__button">{props.buttonText}
                            <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.1692 0.549995L23.5295 2.23707L30.4861 9.39461H0.25V11.7806H30.4859L23.5295 18.9379L25.1692 20.625L34.925 10.5875L25.1692 0.549995Z"/>
                            </svg>
                        </button>
                    }
                </div>
            </div>
        </Link>
        </div>
    </div>
  )
}
